import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Container from '../../components/global/container';
import GetInTouch from '../../components/global/get-in-touch';
import Header from '../../components/global/header';
import SEO from '../../components/global/seo';
import GraphQLErrorList from '../../components/graphql-error-list';
import PortableText from '../../components/portableText';
import WorkCardList from '../../components/work/work-card-list';
import WorkFilters from '../../components/work/work-filters';
import Layout from '../../containers/layout';
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes
} from '../../lib/helpers';

export const query = graphql`
  query RetailPageQuery {
    static: sanityPage(slug: { current: { eq: "work" } }) {
      _rawHeading
      _rawIntro
      seo {
        title
        keywords
        description
        image {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }
    posts: allSanityWork(sort: { fields: order }, filter: {sector: {elemMatch: {title: {eq: "Retail"}}}}) {
      edges {
        node {
          id
          title
          subtitle
          slug {
            current
          }
          cardMedia {
            image {
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              asset {
                _id
                url
              }
            }
            video {
              asset {
                url
              }
            }
            alt
          }
          _rawExcerpt
          _rawExcerptStat
          sector {
            title
          }
        }
      }
    }
  }
`;

const RetailPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  // const site = (data || {}).site;
  const content = (data || {}).static;
  const workNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  let sectorNodes = [];

  workNodes &&
    workNodes.map((secNode) => {
      secNode.sector &&
        secNode.sector.map((sec) => {
          sectorNodes.push(sec.title);
        });
    });

  sectorNodes = [...new Set(sectorNodes.flat(1))];

  // if (!site) {
  //   throw new Error(
  //     'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
  //   );
  // }

  const [filterState, setFilterState] = useState('all');

  const changeFilter = (title) => {
    setFilterState(title);
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.25
  });

  const query = new URLSearchParams(props.location.search);
  const sector = query.get('sector');

  return (
    <>
      <Header headerStyle="clear" />
      <Layout>
      {content.seo && (
          <SEO
          title={content.seo.title !== null ? content.seo.title : false}
          description={content.seo.description !== null ? content.seo.description : false}
          keywords={content.seo.keywords !== null ? content.seo.keywords : false}
          image={content.seo.image !== null ? content.seo.image : false}
        />
        )}
        <Container>
          <section className={`section site-wide work-card-header ${inView ? 'in-view' : ''}`} ref={ref}>
            <div className="hero hero--animation">
              {content._rawHeading && <PortableText blocks={content._rawHeading} />}
            </div>
          </section>

          {workNodes && <WorkCardList nodes={workNodes} selected={filterState} sector={sector ? sector : 'all'} />}
        </Container>
      </Layout>
    </>
  );
};

RetailPage.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object
};

export default RetailPage;
